import { JwtHelperService } from "@auth0/angular-jwt";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router, public jwtHelper: JwtHelperService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUserToken = localStorage.getItem("currentUserToken");
    if (
      currentUserToken /*&& request.url.match(/^https:\/\/.*api\.ecomfort\.com\.br/)*/
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUserToken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    }

    // return next.handle(request);
    try {
      return next.handle(request);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.router.navigate(["/login"]);
        }
      }
      // Other case throw an error
      return Observable.throw(error);
    }
  }
}
