import { TranslateJsonService } from "./../_services/translate-json.service";
import { User } from "./../models/user.model";
import { Injectable } from "@angular/core";
//import { Http, Headers, RequestOptions } from '@angular/http';
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import * as config from "../config";
//import { timer } from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { timeout, timeoutWith } from "rxjs/operators";
import { ErrorObservable } from "rxjs/observable/ErrorObservable";

import {
  HttpParams,
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
const url = config.serverURL; //+ '/ecomfort/v1';
const TIMEOUT: number = 5000; // 5 seconds

@Injectable()
export class LoginService {
  public token: string;
  public userID: string;
  language;
  jsonWord;
  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private translateJson: TranslateJsonService
  ) {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.token = currentUser && currentUser.token;
    this.userID = currentUser && currentUser.userID;
    /*   this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'pt';
    this.jsonWord = this.translateJson.changeLanguage(this.language);
    console.log(this.jsonWord,this.language)*/
  }

  login(data) {
    /*const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');*/
    const headers = new HttpHeaders().set("Content-Type", "application/json");

    //console.log(url)
    //console.log(window.location.hostname)
    //Endpoint : /user/auth
    return (
      this.http
        .post(url + "/user/auth", JSON.stringify(data), { headers })
        // tslint:disable-next-line:no-shadowed-variable
        .map(
          (data) => {
            const response = data; //.json();
            if (response && response["data"]) {
              let userId = this.jwtHelper.decodeToken(
                response["data"].token
              )._id;
              localStorage.setItem(
                "currentUser",
                JSON.stringify(response["data"])
              );
              localStorage.setItem("currentUserToken", response["data"].token);
              localStorage.setItem(
                "refreshToken",
                response["data"].refreshToken
              );
              localStorage.setItem("currentUserId", userId);
              var linguagem = this.cookieService.get(userId).trim()
                ? JSON.parse(this.cookieService.get(userId))["language"]
                : "pt";
              localStorage.setItem("language", linguagem);
              var sessionData = this.cookieService.get(userId)
                ? JSON.parse(this.cookieService.get(userId))
                : [];
              // Carrega dados da última sessão
              if (
                sessionData &&
                (sessionData["currentGateway"] ||
                  sessionData["currentEnvironment"] ||
                  sessionData["language"])
              ) {
                try {
                  sessionData = JSON.parse(this.cookieService.get(userId));
                } catch (err) {
                  sessionData = {};
                }
                localStorage.setItem(
                  "currentGateway",
                  sessionData.currentGateway
                );
                localStorage.setItem(
                  "currentEnvironment",
                  sessionData.currentEnvironment
                );
                localStorage.setItem("language", sessionData.language);
                this.cookieService.set(
                  userId,
                  JSON.stringify({
                    currentGateway: sessionData.currentGateway
                      ? sessionData.currentGateway
                      : "",
                    currentEnvironment: sessionData.currentEnvironment
                      ? sessionData.currentEnvironment
                      : "",
                    language: linguagem ? linguagem : "pt",
                  })
                );
                /* if (sessionData.currentGateway){
              localStorage.setItem('currentGateway', sessionData.currentGateway);
              //this.cookieService.set(userId, JSON.stringify({ currentGateway: sessionData.currentGateway}));
            }else{
              this.cookieService.set(userId, JSON.stringify({ currentGateway: ''}));
            }
            if (sessionData.currentEnvironment){
              localStorage.setItem('currentEnvironment', sessionData.currentEnvironment);
            }else{
              this.cookieService.set(userId, JSON.stringify({ currentEnvironment: '' }))
            }
            if (sessionData.language) {
              localStorage.setItem('language', sessionData.language)
            } else {
              this.cookieService.set(userId, JSON.stringify({ language: linguagem }))
            }*/
              } else {
                this.cookieService.set(
                  userId,
                  JSON.stringify({
                    currentGateway: "",
                    currentEnvironment: "",
                    language: linguagem ? linguagem : "pt",
                  })
                );
              }
              sessionData = JSON.parse(this.cookieService.get(userId));
              let body = {
                userId: userId,
                response: response,
                language:
                  sessionData && sessionData.language
                    ? sessionData.language
                    : linguagem,
                session: {
                  tokenId: response["data"].token.token,
                  deviceId: ".",
                },
              };
              //   this.userService.updateSessionUser(userId,body)
              return body;
            } else {
              this.logout();
              return response;
            }
          },
          (err) => {}
        )
        .pipe(
          timeoutWith(
            TIMEOUT,
            ErrorObservable.throw(
              new Response(null, {
                status: 408,
                statusText: "Tempo limite de resposta atingido",
              })
            )
          )
        )
    );
  }

  logout() {
    this.token = null;
    this.userID = null;
    localStorage.clear();
  }

  register(data): Observable<any> {
    return this.http.post(url + "/user", data).pipe(
      timeoutWith(
        TIMEOUT,
        ErrorObservable.throw(
          new Response(null, {
            status: 408,
            statusText: "Tempo limite de resposta atingido",
          })
        )
      )
    );
  }

  forgot(data): Observable<any> {
    return this.http.post(url + "/user/recovery-password", data).pipe(
      timeoutWith(
        TIMEOUT,
        ErrorObservable.throw(
          new Response(null, {
            status: 408,
            statusText: "Tempo limite de resposta atingido", // this.jsonWord.errors.timeoutServer//
          })
        )
      )
    );
  }

  recovery(data, token): Observable<any> {
    console.log("TOKEN: ", token);
    // let headers = new HttpHeaders()
    //   .set("Authorization", `Bearer ${token}`)
    //   .set("Content-Type", "application/json")
    //   .set("Accept", "application/json");
    /*headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    let tokenHeader= `Bearer ${token}`*/

    localStorage.setItem("currentUserToken", token);

    let options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    };
    return this.http.post(url + "/user/recovery", data, options).pipe(
      timeoutWith(
        TIMEOUT,
        ErrorObservable.throw(
          new Response(null, {
            status: 408,
            statusText: "Tempo limite de resposta atingido", //this.jsonWord.errors.timeoutServer//
          })
        )
      )
    );
  }

  checkRecoveryToken(token): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    let options = { headers: headers }; //new RequestOptions({ headers: headers });
    return this.http.post(url + "/user/recovery", {}, options).pipe(
      timeoutWith(
        TIMEOUT,
        ErrorObservable.throw(
          new Response(null, {
            status: 408,
            statusText: "Tempo limite de resposta atingido", //this.jsonWord.errors.timeoutServer//
          })
        )
      )
    );
  }

  confirm(token) {
    return this.http
      .get(url + "/user/confirmation", { params: { token } })
      .pipe(
        timeoutWith(
          TIMEOUT,
          ErrorObservable.throw(
            new Response(null, {
              status: 408,
              statusText: "Tempo limite de resposta atingido", //this.jsonWord.errors.timeoutServer//
            })
          )
        )
      );
  }
}
