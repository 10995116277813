import { PanelComponent } from "./panel/panel.component";
import { GroupModalModule } from "./_directives/group-modal/group-modal.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { LogsService } from "./_services/logs.services";
import { SanitizeHtmlPipe } from "./_directives/sanitizeHtmlPipe";

import { ResponseService } from "./_services/response.service";
import { UserModule } from "./user/user.module";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RuleService } from "./_services/rule.service";
import { ScenarioService } from "./_services/scenario.service";
import { ClickOutsideModule } from "ng-click-outside";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule,
} from "ngx-toastr";

import { AppComponent } from "./app.component";
import { HomeModule } from "./home/home.module";
//import { HttpClientModule } from '@angular/http';
import { JwtInterceptor } from "./_helpers/jwt.interceptor";
import { AppBootstrapModule } from "./app-bootstrap.module";
import { AlertService } from "./_services/alert.service";
import { AuthGuard } from "./_guards/auth.guard";
import { DeviceService } from "./_services/device.service";
import { LoginService } from "./login/login.service";
import { CookieService } from "ngx-cookie-service";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { ImageCropperModule } from "ngx-image-cropper";
import {
  IMqttMessage,
  MqttService,
  MqttModule,
  IMqttServiceOptions,
} from "ngx-mqtt";
import { RouterModule } from "@angular/router";
import { LoginModule } from "./login/login.module";
import { DirectivesModule } from "./_directives/directives.module";
import { TranslateJsonService } from "./_services/translate-json.service";
import { Safe } from "./_directives/alert.component";
//import { EventListener } from '@angular/core/src/debug/debug_node';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  protocol: "wss",
  hostname: "mqtt.ecomfort.com.br", //"mqtt.ecomfort.com.br",
  port: 61619, //15673,
  path: "/ws",
  username: "ecomfort-web",
  password: "ecomfort*2018",
  cryptoKey: "pixel",
  cryptoMethod: "AES",
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
export function mqttServiceFactory() {
  return new MqttService(MQTT_SERVICE_OPTIONS);
}
/*export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}*/

@NgModule({
  declarations: [AppComponent, SanitizeHtmlPipe, Safe],
  imports: [
    AppRoutingModule,
    AppBootstrapModule,
    BrowserModule,
    DirectivesModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    // HttpClientModule,
    HttpClientModule,
    HomeModule,
    LoginModule,
    UserModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS, undefined), //MQTT_SERVICE_OPTIONS),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      autoDismiss: true,
      timeOut: 8000,
      maxOpened: 4,
      easing: "ease-out",
      positionClass: "toast-bottom-center",
      preventDuplicates: true,
      toastClass: "toast custom-toast toast-error",
      closeButton: true,
    }),
  ],
  providers: [
    AuthGuard,
    AlertService,
    LoginService,
    DeviceService,
    ScenarioService,
    RuleService,
    ResponseService,
    LogsService,
    SanitizeHtmlPipe,
    Safe,
    TranslateJsonService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [DirectivesModule, SanitizeHtmlPipe, Safe],
})
export class AppModule {}
